import {
  useBeginCreateAsset,
  useFinalizeCreateAsset,
  useUploadPresignedFileUrl,
} from 'api/useAssetsApi';
import { FullscreenLoadingWithComponent } from 'components/Loading';
import { useEffect, useRef, useState } from 'react';
import {
  calculateChecksumAsync,
  convertPdfToImageAsync,
  getMetadataForAudioAsync,
  getMetadataForImageAsync,
  getMetadataForVideoAsync,
} from 'utils/file-util';
import { EnterAddressDialog } from './EnterAddressDialog';
import {
  AssetMetadataWithValue,
  AssetResponse,
  EAssetSource,
  EEntityState,
} from 'api/core';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-toastify';
import { analyticsEvent } from 'utils/google-analytics';

interface AssetUploadProps {
  fileInputRef: React.RefObject<HTMLInputElement>;
  projectId?: string;
  onAssetUploaded?: (asset: AssetResponse) => void;
  accept?: string;
  allowMultiple?: boolean;
  source?: EAssetSource;
  enableAddressNaming?: boolean;
  convertPdfToImage?: boolean;
}

export const AssetUpload = ({
  fileInputRef,
  projectId,
  onAssetUploaded,
  accept,
  allowMultiple,
  source,
  enableAddressNaming,
  convertPdfToImage = false,
}: AssetUploadProps) => {
  const { mutateAsync: createAssetAsync } = useBeginCreateAsset();
  const { mutateAsync: uploadFileAsync } = useUploadPresignedFileUrl();
  const { mutateAsync: markAsync } = useFinalizeCreateAsset();
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({
    items: [],
    state: 'idle',
  });
  const [uploadFn, setUploadFn] =
    useState<(address: string | null) => Promise<void>>();
  const [showRenameDialog, setShowRenameDialog] = useState(false);

  const prevUploadState = useRef(uploadStatus.state);
  useEffect(() => {
    if (
      prevUploadState.current === 'uploading' &&
      uploadStatus.state === 'idle'
    ) {
      const failedItemNames = uploadStatus.items
        .filter((e) => e.state === 'error')
        .map((e) => e.refFile.name);

      if (failedItemNames.length > 0) {
        toast.error(
          'Følgende filer mislykkedes under upload: ' +
            failedItemNames.join(', ')
        );
      }
    }

    prevUploadState.current = uploadStatus.state;
  }, [uploadStatus]);

  const handleFileUpload = async (file: File, address?: string | null) => {
    const originalFileRef = file;
    updateFileRefData(originalFileRef, {
      state: 'uploading',
      progress: 0,
    });

    analyticsEvent({
      category: 'asset_upload',
      label: 'asset_upload_label',
      action: 'asset_upload',
    });

    // If PDF file, convert to image
    if (
      convertPdfToImage &&
      (file.type === 'application/pdf' ||
        file.name.toLocaleLowerCase().endsWith('.pdf'))
    ) {
      await convertPdfToImageAsync(file).then((image: Blob) => {
        const newFilename =
          (file.name.toLocaleLowerCase().endsWith('.pdf')
            ? file.name.substring(0, file.name.length - 4)
            : file.name) + '.png';
        file = new File([image], newFilename, {
          type: 'image/png',
        });
      });
    }

    let metadata: AssetMetadataWithValue[] = [];
    try {
      if (file.type.startsWith('video/')) {
        metadata = await getMetadataForVideoAsync(file);
      } else if (file.type.startsWith('image/')) {
        metadata = await getMetadataForImageAsync(file);
      } else if (file.type.startsWith('audio/')) {
        metadata = await getMetadataForAudioAsync(file);
      }
    } catch (error) {
      console.warn('Error getting video duration', error);
    }

    const sha256 = await calculateChecksumAsync(file);
    const fileName = address ? `${address}, ${file.name}` : file.name;
    const assetResponse = await createAssetAsync({
      assetCreateRequest: {
        projectId,
        entityState: EEntityState.Active,
        fileName,
        mimeType: file.type,
        fileSize: file.size,
        checksumSha256: sha256,
        source: source || EAssetSource.UserUploaded,
        metadata,
      },
    });

    if (assetResponse.foundMatchingAsset) {
      updateFileRefData(originalFileRef, {
        state: 'success',
        progress: 100,
      });

      onAssetUploaded?.({
        id: assetResponse.id,
        projectId: assetResponse.projectId,
        lastModifiedUtc: assetResponse.lastModifiedUtc,
        createdUtc: assetResponse.createdUtc,
        type: assetResponse.type,
        fileSize: assetResponse.fileSize,
        isGlobal: false,
        originalFileName: assetResponse.originalFileName,
        tags: [],
        url: assetResponse?.url || '',
        previewUrl: assetResponse.previewUrl,
        userId: assetResponse.userId,
        source: assetResponse.source,
      });

      analyticsEvent({
        category: 'asset_upload',
        label: 'asset_upload_success_existing_label',
        action: 'asset_upload_success_existing',
      });

      return;
    }

    await uploadFileAsync({
      url: assetResponse.presignedUploadUrl,
      file,
      onProgress: (progress) => {
        updateFileRefData(originalFileRef, {
          state: 'uploading',
          progress,
        });
      },
    });

    const result = await markAsync({ id: assetResponse.id });
    updateFileRefData(originalFileRef, {
      state: 'success',
      progress: 100,
    });
    onAssetUploaded?.(result);

    analyticsEvent({
      category: 'asset_upload',
      label: 'asset_upload_success_label',
      action: 'asset_upload_success',
    });
  };

  const updateFileRefData = (
    file: File,
    data: {
      state?: UploadItem['state'];
      progress?: number;
      increaseAttempts?: boolean;
    }
  ) => {
    setUploadStatus((curr) => {
      return {
        state: 'uploading',
        items: curr.items.map((item) => {
          if (item.refFile === file) {
            return {
              ...item,
              state: data.state || item.state,
              progress: data.progress ?? item.progress,
              attempts: data.increaseAttempts
                ? item.attempts + 1
                : item.attempts,
            };
          }
          return item;
        }),
      };
    });
  };

  const handleFiles = async (files: FileList) => {
    setUploadStatus({
      state: 'idle',
      items: Array.from(files).map((file) => ({
        state: 'idle',
        progress: 0,
        refFile: file,
        attempts: 0,
      })),
    });

    if (files.length < 1) return;

    const startUploadingAsync = async (address: string | null) => {
      setUploadStatus((curr) => ({ ...curr, state: 'uploading' }));
      for (let i = 0; i < files.length; i++) {
        try {
          await handleFileUpload(files[i], address);
        } catch (error) {
          console.error('Error uploading file', error);
          const attempts = uploadStatus.items[i].attempts;

          updateFileRefData(files[i], {
            state: 'error',
            progress: 0,
            increaseAttempts: true,
          });

          analyticsEvent({
            category: 'asset_upload',
            label: 'asset_upload_error_label',
            action: 'asset_upload_error',
          });

          if (attempts < 3) {
            i--;
          }
        }
      }
      setUploadStatus((curr) => ({ ...curr, state: 'idle' }));
    };

    if (files.length === 1 || !enableAddressNaming) {
      await startUploadingAsync(null);
    } else {
      setUploadFn(
        () => (address: string | null) => startUploadingAsync(address)
      );
      setShowRenameDialog(true);
    }
  };

  return (
    <>
      <input
        type="file"
        multiple={allowMultiple}
        ref={fileInputRef}
        accept={accept}
        onChange={async (event) => {
          const files = event.target.files;
          if (!files) return;
          await handleFiles(files);
        }}
        className="hidden"
      />
      {uploadStatus.state === 'uploading' ? (
        <FullscreenLoadingWithComponent>
          <div className="w-full max-w-lg mx-auto p-4 bg-gray-200 shadow-lg rounded-lg text-primary-content">
            <h2 className="text-lg font-semibold mb-4">Uploader filer</h2>
            <div className="space-y-4">
              {uploadStatus.items.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col space-y-2 p-3 bg-base-200 rounded-lg"
                >
                  <span className="font-medium text-sm truncate">
                    {item.refFile.name}
                  </span>
                  <div className="flex items-center space-x-2">
                    <progress
                      value={item.progress}
                      max="100"
                      className={twMerge(
                        'w-full progress',
                        item.state === 'error' && 'progress-error',
                        item.state === 'success' && 'progress-success',
                        item.state === 'uploading' && 'progress-primary',
                        item.state === 'idle' && 'progress-secondary'
                      )}
                    />
                    <span className="text-sm font-medium">
                      {item.progress}%
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </FullscreenLoadingWithComponent>
      ) : null}

      {enableAddressNaming && (
        <EnterAddressDialog
          isOpen={showRenameDialog}
          setIsOpen={setShowRenameDialog}
          fileCount={uploadStatus.items.length + 1}
          onAddressConfirmed={(address) => {
            setShowRenameDialog(false);
            uploadFn?.(address);
          }}
          onCancel={() => {
            setShowRenameDialog(false);
            uploadFn?.(null);
            setUploadFn(undefined);
          }}
        />
      )}
    </>
  );
};

interface UploadStatus {
  state: 'uploading' | 'idle';
  items: UploadItem[];
}

interface UploadItem {
  state: 'uploading' | 'idle' | 'success' | 'error';
  progress: number;
  refFile: File;
  attempts: number;
}
